import StrictOrg from './StrictOrg.react';

export default {
  metaInfo() {
    return { title: 'Fairwinds Insights | Strict Organization' };
  },
  components: {
    'strict-org': StrictOrg,
  },
  methods: {
    route() {
      return this.$route;
    },
    router() {
      return this.$router;
    },
  },
};
