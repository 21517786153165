import React from 'react';

import { IRoute, IRouter } from '~globalTypes';

import './StrictOrg.react.scss';
import StrictSAMLOrganizationCard from '~views/organizations/StrictSAMLOrganization.react';

type StrictOrgProps = {
  route: IRoute;
  router: () => IRouter;
};

const StrictOrg = ({ route, router }: StrictOrgProps) => {
  return <StrictSAMLOrganizationCard router={router} orgName={route.params?.org} />;
};

export default StrictOrg;
