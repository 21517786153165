import React from 'react';
import { IRouter } from '~utils/global.types.react';

import './StrictSAMLOrganization.react.scss';

type StrictSAMLOrganizationCardProps = {
  router: () => IRouter;
  orgName: string;
};

const StrictSAMLOrganizationCard = ({ router, orgName }: StrictSAMLOrganizationCardProps) => {
  const organizationRoute = orgName
    ? router().resolve({
        name: 'default-org',
        params: { org: orgName },
      }).href
    : '';

  return (
    <div className="card mini-card mb-0 bg-light">
      <div className="card-body mixin">
        <h2 className="card-title mb-0">
          <a href={organizationRoute}>{orgName}</a>
        </h2>
        <p>
          This organization has SAML <b>strict mode</b> active. You need to{' '}
          <a href={`/auth/logout?login-sso=${orgName}`} style={{ color: '#0000EE' }}>
            sign-in via SSO
          </a>{' '}
          to be able to see its data
        </p>
        <span className="float-right"></span>
        <i className="fa fa-fw fa-user" aria-hidden="true"></i>
      </div>
    </div>
  );
};

export default StrictSAMLOrganizationCard;
